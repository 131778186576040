























import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';
import { uuid } from 'vue-uuid';

@Component({})
export default class ToggleSwitch extends Vue {
  @Prop({ type: String, default: '' }) id!: string;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  @PropSync('value', { type: Boolean, required: true }) valueSync!: boolean;

  inputId: string = this.id;

  beforeMount() {
    if (!this.inputId) {
      this.inputId = `toggle-switch-${uuid.v4().slice(0, 8)}`;
    }
  }

  toggleValue() {
    this.valueSync = !this.valueSync;
  }
}
